.App {
  text-align: center;
  height: 50%;
  margin: 0;
  padding: 0;
}


body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto'
}

h1,
h2,

h3 {
  font-family: 'Roboto'
  /* Apply the font to headings */
}

p {
  font-family: 'Roboto'
  /* Apply the font to paragraphs */
}

#root {
  min-height: 100vh;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}

@font-face {
  font-family: 'Rowdies';
  src: url('../fonts/Rowdies-Regular.ttf') format('truetype');
  /* Adjust the file path as needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
  /* Adjust the file path as needed */
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 13px; /* set your desired font size here */
  font-family: 'Open Sans', sans-serif;
  color: #333; /* set your desired font color here */
}

.px-border {
  border: '1px solid rgba(0, 0, 0, 0.05)',
}

;

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.parent-container {
  /* background-color: blueviolet; */
  /* height: calc(100% - 56px);; */
  height: calc(100% - 56px);
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  overflow: none;
  
  /* justify-content: 'space-between'; */
}

.top-menu {
  /* background-color: gray; */
  margin: 0;
  padding-left: 50;
  width: 100%;
  height: 56;
}

.child-left {
  /* background-color: yellow; */
  width: 275;
  /* height: calc(100%); */
  /* flex-direction: 'row'; */
  /* justify-content: 'flex-start'; */
  margin: 0;
  padding: 0;
  position: absolute;

  z-index: 2;
}



.right-panel {
  float: right;
  background-color: white;
  overflow: hidden;
  height: 100%;
  width: 395px;
  border-top: 0.5px solid gray;
  border-left: 0.5px solid gray;

}

.right-panel-open-playground {
  display: none;

}


.right-panel-components {
  /* background-color: blueviolet; */
  width: 100%;
  height: calc(100% - 100px);
  /* margin: 20;
  padding: 20; */
  overflow: auto;
  /* justify-content: 'space-between'; */
}



.steps {
  position: absolute;
  background-color: white;
  border-color: red;
  border-width: 10;
  padding:10px;
  width: 100%;
  height: 600px;
  /* height: 100%; */
  /* overflow: hidden; */
}



.canvas {
  float: left;

  /* position: absolute; */
  top: 0;
  left: 0;
  /* width: calc(100% - 395px);; */
  width: calc(100%);
    
  height: calc(100% - 75px);;
  background-color: white;
  align-items: 'center';
}

.canvas-view-mode {
  
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  
}




.left-panel {
  position: absolute;
  bottom: 20px;
  /* width: 200px; */
  left: 50%;
      /* Horizontally center the element */
  transform: translateX(-50%) ;
      /* Move the element left by 50% of its own width */
  z-index: 1;
  /* background-color: #C8C8C8; */
}



.main-canvas {
  background-color: white;
  /* height: 100%; */
  
  align-items: 'center';
  /* flex-direction: 'row'; */
  /* justify-content: 'flex-end'; */
  overflow: scroll;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  position: absolute;

  z-index: 1;
}


.left-pane-internal {
  /* background-color: yellow; */
  /* height: 100%;
  width: 100%; */
  margin: 10;
  display: flex;
  flex-direction: column;
  /* background-color: #C8C8C8; */
  /* justify-content: 'center'; */
  /* align-items: 'center'; */
}

.large-container {
  /* width: 3000;
  height: 3000; */
  overflow-x: scroll;
  overflow-y: scroll;
}

.scroll-container {
  width: calc(100% );
  height: calc(100%);
  /* overflow: auto; */
  overflow: auto;
  /* margin: 10px; */
  border: 0.5px solid grey;
  /* background-color: brown; */
}


.component-left-review {
  width: 32px;
  height: 32px;
  /* padding-top: 5px; */
  /* border: 0.5; */
  /* border-color: "gray"; */
  
}


 .stage {
  /* background-color: darkgoldenrod; */
  height: 1000px;
  width: 1000px;
} 

.scrollable {
  background-color: white;
  border-color: red;
  border-width: 10;
  overflow: 'auto';
  white-space: nowrap;
}







.canvas-background {  
  height: 100;
  width: 100;
}


.stage1 {
  /* background-image: url('../res/bg-patter.svg'); */
  background-image: url('../res/canvas-bg-pattern.svg');
  background-repeat: repeat;
  /* background-size: 10px 10px; */
  /* width: 100px;
  height: 100px; */

  
}


.horizontal-container {
  display: flex;
  justify-content: space-between;
  /* Center vertically */
}

/* Optional styles to add space between label and ">" */
.horizontal-container>div {
  margin-left: 5px;
  /* Adjust as needed for spacing */
}


.parent {
  pointer-events: none;
}

.child {
  pointer-events: all;
}

textarea {
  white-space: normal;
  text-align: justify;
  -moz-text-align-last: center;
  height: auto;
  /* Firefox 12+ */
  text-align-last: center;

}

.select-box {
  padding: 5px 10px;
  border-radius: 10px;
  border: 0.5px solid #DDDDDD;
  font-size: 16px;
  
  cursor: pointer;
}

.select-box-no-border {
  
  font-size: 16px;
  border: 0px;
  cursor: pointer;
}


.play-button {
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  background-color: #3f51b5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  
  /* &:hover {
    transform: scale(1.1);
  } */
}

.play-button:before {
  content: "";
  border-style: solid;
  border-width: 6.25px 0 6.25px 10px;
  border-color: transparent transparent transparent white;
}

.stop-button {
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  background-color: #f44336;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  /* &:hover {
    transform: scale(1.1);
  } */
}

.stop-button:before {
  content: "";
  width: 12.5px;
  height: 12.5px;
  background-color: white;
  border-radius: 6.25px;
  display: inline-block;
}



/* Nav-bar */

.dropdown-menu li {
  position: relative;
  }
  .dropdown-menu .dropdown-submenu {
  /* display: none; */
  position: absolute;
  left: 100%;
  top: -7px;
  }
  .dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
  }
  .dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
  

  }

  .left-options-container {
    display: flex;
    flex-direction: row;
    /* background-color: #C8C8C8; */
  }
  .left-options-box {
    /* padding: 10px; */
    background-color: #F1F1F1;
    /* border-radius: 6px; */
      display: "flex";
      align-items: center;
      padding-top: 10px;
      padding-left: 10px;
          
    /* align-items: "center"; */
    /* margin: 5px; */
  }



  .accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-buttons {
    display: flex;
  }
  
  /* .play-button, .stop-button {
    margin-left: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  } */
  
  .play-button:hover, .stop-button:hover {
    /* background-color: #f0f0f0; */
    border: 2px solid #f0f0f0;

  }

  .accordion-header-custom {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }
  
  

  .accordion-header-custom span {
    flex: 1;
    width: 80%;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
  }

  /* .tab-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
    z-index: 1;

} */


.tab-buttons::-webkit-scrollbar {
  display: none;
    flex-grow: 1;
      z-index: 1;


}

.tab-button {
  background-color: #f2f2f2;
  border: none;
  color: black;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
  margin-right: 4px;
  border-radius: 20px;
  transition: background-color 0.3s;
}

.tab-button.active {
  background-color: #0c8eff;
  color: white;
}



.tab-button:not(:last-child) {
  margin-right: 8px;
}



.add-tab-button {
  background-color: #f2f2f2;
  border: none;
  color: black;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
  margin-left: 4px;
  border-radius: 20px;
  transition: background-color 0.3s;
    order: 1;
      z-index: 1;


}

.tab-content {
  display: none;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  overflow: auto;
}

.tab-content.active {
  display: block;
}

.tab-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 0 20px;
}



.tab {
  /* flex-basis: auto; */
  /* margin-right: 10px; */
  /* padding: 10px 20px; */
  cursor: pointer;
  flex: 1;
  width: 395px;
  font-weight: bold;
  color: "black";
    background-color: "white";
  /* border: 1px solid transparent; */
}

.tab-active {
  border-bottom: 2px solid #4C3FDC;
  flex: 1;
    width: 395px;
    
    color: "#4C3FDC";
    background-color: "#4C3FDC22";
      
}

.tab-content {
  padding: 20px;
}

.login-popup-container {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.7);
}

.left-align {
  text-align: left;
}
.login-popup-content {
  background-color: white; 
   margin: 15% auto; 
   padding: 20px; 
   border: 1px solid #888;
  width: 50%;
  height: 85%;
    position: absolute;
      /* top: 20%; */
      left: 50%;
      transform: translate(-50%, -25%);
      /* max-width: 600px; */
  
  /* position: relative; */
}

.login-popup-content button#close-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.login-popup-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list li {
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.list hr {
  border: none;
  height: 1px;
  margin: 0;
  background-color: #ccc;
}

.css-1aquho2-MuiTabs-indicator {
 
  background-color: red;
 
}

.muitabpanel-root-custom {
  padding: 0; /* remove padding */
  margin: 0; /* remove margin */
  background-color: "#F9F5F5";
  overflow: "auto";
  height:"100%";
  
  
}

/* Dropdown container */
.search-dropdown {
  /* position: relative; */
  /* display: inline-block; */
  padding: 20px;
}

/* Set the width to 100% */
.search-dropdown-list-container {
  width: 100%;
  overflow: scroll;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

}




/* Create a card-like view with a border and padding */
.search-dropdown-list-card {
  /* border: 1px solid #ccc; */
  
  height: 50px;
  cursor: pointer;
  background-color: white;
  padding-left: 25px;
  padding-right: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: "none";
  display: grid;
  align-items: "center";
  grid-template-columns: 1fr auto;
  z-index: 10;
  transition: background-color 0.3s;
          /* Add a smooth transition effect */
      
  /* padding: 10px;
  margin-bottom: 10px; */
}

.search-dropdown-list-card:hover {
  background-color: #f0f0f0;
  /* Change the background color on hover */
}

.horizontal-divs {
  display: flex;
  flex-wrap: nowrap; /* Prevents wrapping to next line */
  overflow-x: auto; /* Allows horizontal scrolling if necessary */
}

.menu {
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  padding: 5px 0 5px 0;
  width: 150px;
  height: auto;
  margin: 0;
  /* use absolute positioning  */
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
}



.choose-diagram {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: row;
  padding: 10px;
  cursor: pointer;
  overflow: auto; /* Add this line to make it scrollable */
  position: relative;
  height: 70%;
}

.choose-diagram-title {

  margin: 10px;
  font-size: 20px;
  font-weight: bold;
  padding-right: 10px;
  cursor: pointer;
}

.choose-diagram-delete-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.confirmation-popup-container {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.confirmation-popup {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.confirmation-popup p {
  margin: 0 0 20px 0;
}

.confirmation-popup-buttons {
  display: flex;
  justify-content: center;
}

.confirmation-popup-confirm,
.confirmation-popup-cancel {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
}

.component-info-close {
  color: white;
  background-color: #dc3545;
  border: none;
    border-radius: 5px;
    cursor: pointer;
      margin-top: 20px;
      height: 44px;
      width: 100px;
        /* Add some top margin */
}

.confirmation-popup-cancel {
  background-color: #dc3545;
}



.context-menu {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  padding: 2px 0;
  position: absolute;
  z-index: 9999;
}

.context-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap; /* Prevent text from wrapping */

}

.context-menu li {
  cursor: pointer;
  padding: 8px 20px;
}

.context-menu hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 5px 0;
}










.SearchChildComp__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SearchChildComp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  /* max-width: 400px; */
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .1);
}

.SearchChildComp__InputWrapper {
  width: 100%;
  margin-bottom: 16px;
}

.SearchChildComp__Input {
  border: none;
  border-bottom: 2px solid #ccc;
  padding: 12px;
  font-size: 20px;
  width: 100%;
  background-color: transparent;
}

.SearchChildComp__Results {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); */
  grid-gap: 20px;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
  text-align: left; /* Add this property to align the child elements to the left */
}

.SearchChildComp__ResultCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  text-align: left; /* Add this property to align the child elements to the left */
  box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.1);
}

.SearchChildComp__ResultCardTitle {
  
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: left;
}

.SearchChildComp__ResultCardDescription {
  font-size: 16px;
  color: #666;
}


/* .SearchChildComp__Results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
} */

.SearchChildComp__ResultItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px;
  font-size: 18px;
  color: #333;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.SearchChildComp__ResultItem:hover {
  background-color: #e0e0e0;
}

.SearchChildComp__Loading {
  margin-top: 16px;
  font-size: 18px;
  color: #666;
}

.SearchChildComp__Empty {
  margin-top: 16px;
  font-size: 18px;
  color: #666;
}

.SearchChildComp__CloseButton {
  margin-top: 24px;
  border: none;
  background-color: #333;
  color: #fff;
  padding: 12px 24px;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.SearchChildComp__CloseButton:hover {
  background-color: #222;
}




.ReferenceCardView__Container {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.ReferenceCardView__Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.ReferenceCardView__Title {
  font-size: 24px;
  font-weight: bold;
  padding-left: 10px;
}

.ReferenceCardView__EditButton {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
}

.ReferenceCardView__Content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ReferenceCardView__Description {
  font-size: 18px;
  margin-right: 16px;
}

.ReferenceCardView__Button {
  background-color: #ccc;
  border-radius: 4px;
  padding:10px;
  margin-left: 8px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}


.email-bubble {
  display: inline-block;
  background-color: #f2f2f2;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
}

.email-bubble span {
  margin-right: 10px;
}

.remove-button {
  border: none;
  background-color: transparent;
  color: #c9c9c9;
  font-size: 1.2em;
  cursor: pointer;
}

.remove-button:hover {
  color: #333;
}


.variable-select-dropdown-search {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.variable-select-dropdown {
  position: relative;
}

.variable-select-dropdown input {
  width: 100%;
  padding: 8px;
  border: none;
  border-bottom: 1px solid #ccc;
}

.variable-select-dropdown ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
}

.variable-select-dropdown li {
  padding: 8px;
  cursor: pointer;
}

.variable-select-dropdown li:hover {
  background-color: #f2f2f2;
}

.variable-select-selected-items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.variable-select-selected-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 14px;
}

.variable-select-submit-button {
  margin-top: 16px;
  padding: 8px 16px;
  background-color: #0077ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.variable-select-submit-button:hover {
  background-color: #0066cc;
}




.VariantEditor__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.VariantEditor {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.VariantEditor__Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ccc;
}

.VariantEditor__Header h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.VariantEditor__CloseButton {
  border: none;
  background-color: transparent;
  font-size: 20px;
  font-weight: 600;
  color: #999;
  cursor: pointer;
  padding: 0;
}

.VariantEditor__CloseButton:hover {
  color: #000;
}

.VariantEditor__Content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.VariantEditor__VariantList {
  display: flex;
  flex-direction: column;
}

.VariantEditor__VariantCard {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}

.VariantEditor__VariantCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.VariantEditor__VariantCardHeader input {
  font-size: 16px;
  font-weight: 600;
  padding: 5px 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  margin-right: 10px;
  width: 100%;
}

.VariantEditor__DeleteButton {
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  color: #999;
  cursor: pointer;
  padding: 0;
}

.VariantEditor__DeleteButton:hover {
  color: #f00;
}

.VariantEditor__VariableList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.VariantEditor__VariableCard {
  margin-right: 10px;
  margin-bottom: 10px;
}

.VariantEditor__VariableCard span {
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
}

.VariantEditor__VariableCard input {
  font-size: 14px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 150px;
}

.VariantEditor__AddVariable {
  margin-bottom: 10px;
}

.VariantEditor__AddVariable select {
  font-size: 14px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.VariantEditor__AddVariant button:hover {
  background-color: #0070f3;
  color: white;
}

.VariantEditor__Footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  border-top: 1px solid #ccc;
}

.VariantEditor__Footer button {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #0070f3;
  color: white;
  cursor: pointer;
}

.VariantEditor__Footer button:hover {
  background-color: #0057b8;
}

@media screen and (max-width: 600px) {
  .VariantEditor {
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
  }

  .VariantEditor__Header h2 {
    font-size: 20px;
  }

  .VariantEditor__CloseButton {
    font-size: 18px;
  }

  .VariantEditor__Content {
    padding: 10px;
  }

  .VariantEditor__VariantCardHeader input {
    font-size: 14px;
  }

  .VariantEditor__VariableCard input {
    font-size: 12px;
  }

  .VariantEditor__Footer button {
    font-size: 14px;
    padding: 5px 10px;
  }
}



.playground-container {
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 100%;
  min-height: 0;
  height: auto;
  overflow: none;
  
  /* position: relative; */
}




.playground-actions {
    display: flex;
      gap: 10px;
}




.playground-flow-interaction-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 0;
  height: auto;
  overflow: none;
  position: relative;
  height: 72px;
  /* border: 1px solid #ccc; */
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  /* border-radius: 10px; */
  
  
}



.selected-flow-steps-details {
  max-width: 100%;
  height: 90%;
  padding-top: 10px;
  
  overflow-x: scroll;
  margin-left: 10px;
    
    padding-left: 10px;
    padding-right: 10px;
}

.playground-action-views {
  margin: 10px;
  /* flex: 1; */
  /* width: 40%; */
  /* height: 100%; */
}


.playground-action-view {
  padding: auto;
  /* display: flex;
  align-items: flex-end; */
  /* height: 100%; */

}

.playground-action-icon {
  width: 16px;
  height: 16px;
  margin-right: 16px;
  cursor: pointer;
}


.playground-steps-wrapper {
  /* flex: 1;
  display: flex;
  flex-wrap: wrap; */
  /* width: calc(100% - 500px); */
  width: 100%; 
  height: 100%;
  margin-top: 10px;
  margin-right: 50px;
  overflow: auto;
}

.flow-search-dropdown-list-card {
  border: 1px solid #ccc;
  width: 100%;
  height: 50px;
  z-index: 100;
  cursor: pointer;
  background-color: #fff2f2;
  
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: "none"
}



.playground-steps {
  height: 100%;
  display: flex;
  gap: 10px;
  flex-direction:row;
}


.playground-close-button {
  align-self: flex-end;
  margin: 10px;

}


/* Set the width to 100% */
.playground-search-dropdown-list-container {
  width: 331px;
  height: 50px;
  padding-left: 10px;
  position: fixed;
  overflow: scroll;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 100;

}


.pg-top-items {
    display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      
  /* justify-content: flex-end; */
}

.playground-content {
  /* display: flex;
    flex-direction: row; */
    width: 100%;
}

.playground-select-flow {
flex: 1;
/* border: '1px solid rgba(0, 0, 0, 0.05)', */
}


.playground-select-steps {
  display: flex;
  flex-direction: column;
  flex: 2;
  max-height: 400px;
  
}

.plaground-step-description {
  flex: 4;
  height: 120px;
  width: 100%;
  padding-top: 8px;
  padding-right:20px;
  padding-left: 20px;
  /* border: '1px solid #C76811', */
  
}


.playground-logo-menu {
  display: flex;
    flex-direction: row;
    align-items:  baseline;
}

.more-view-right-panel {
  height: calc(100% - 150px);
  z-index: 20;
  /* overflow: scroll; */
}

.kvf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 20px; */
}


.kvf-delete-button {
  position: absolute;
    top: 10px;
    right: 32px;
    width: 20px;
    height: 20px;
    padding: 0;
    min-width: 0;
    border: 0;
}

.kvf-card {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.kvf-card input {
  height: 26px;
}

.kvf-card input,

.kvf-card textarea {
  margin-bottom: 5px;
  padding: 5px;
  /* border: 1px solid #ccc; */
  border-radius: 3px;
  width: 100%;
  white-space: pre-wrap;
}


.kvf-card-textfield {
  margin-bottom: 5px;
  
  /* padding: 5px; */
  /* border: 1px solid #ccc; */
  /* border-radius: 3px; */
  width: calc(100% - 60px);
  /* height: 60px; */
  /* white-space: pre-wrap; */
  background-color: white;
}

.kvf-button {
  background-color: #4c3fdc;
  color: white;
  border: none;
  width: 90%;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 10px;
  /* margin-right: 30px; */
  cursor: pointer;
}

.kvf-button:hover {
  background-color: #0056b3;
}


/* TextFormatDropdown.css */

.text-format-dropdown-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #f5f5f5;
  /* border: 1px solid #ccc; */
  /* border-radius: 5px; */
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.text-format-dropdown-group {
  margin-bottom: 10px;
}

select,
input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
}

.color-picker-button {
  /* background-color: #007bff; */
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.color-picker-container {
  margin-top: 10px;
  display: none;
}

.apply-color-button {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

/* Responsive adjustments for small screens */
@media (max-width: 600px) {
  .text-format-dropdown-container {
    width: 100%;
  }
}


.color-preview {
  width: 40px;
  height: 20px;
  border: 1px solid #ccc;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.warning-message {
  margin: 20px 10px;
  
  background-color: #ffc107;
  /* Yellow background */
  color: #333;
  /* Dark text color */
  padding: 10px;
  border: 1px solid #ffca28;
  /* Border color */
  border-radius: 4px;
  /* Rounded corners */
  margin-bottom: 10px;
  text-align: center;
}


.component-info-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.component-info-popup {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.component-info-popup-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.component-info-table {
  width: 100%;
  border-collapse: collapse;
}

.component-info-key,
.component-info-value {
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
}

.component-info-key {
  font-weight: bold;
}

.component-info-value {
  color: #333;
}

/* Add more styles as needed */


.custom-dropdown-item:hover {
  background-color: lightblue;
  /* Change the background color to red */
  color: white;
  /* Change the text color for better visibility */
  cursor: pointer;
}

/* Optional: Add a transition for a smoother effect */
.custom-dropdown-item {
  transition: background-color 0.3s ease;
}

.flows-bottom-view {
  background-color: #333;
  color: white;
  
}

/* Styles for the custom view container */
.flow-view-footer-custom {
  position: fixed;
  width: 350px;
  background-color: #fff;
  margin-left: 20px;
  margin-right: 20px;
  bottom:  20px;
  /* Universal padding */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.flow-view-footer-create-step {
  
  /* Full width */
  float: left;
  font-weight: 600;
  
  height: 48px;
  /* Float the child to the left */
  
  /* Set the right margin to create a 20px gap */

  color: #4c3fdc;
  /* Button background color */
  border: 1px solid #4c3fdc;
  /* Text color */
  border-radius: 8px;
  font-size: 14px;
  /* Rounded corners */
  padding: 16px;
  /* Button padding */
  margin-bottom: 20px;
  /* Spacing below the button */
}

/* Style for the "Create Flow" button */
.flow-view-footer-create-flow {
  
  /* Full width */
    float: left;
    font-weight: 600;
    height: 48px;
      /* Float the child to the left */
        
  
  color: #4c3fdc;
    /* Button background color */
    border: 1px solid #4c3fdc;
  /* Text color */
  border-radius: 8px;
  font-size: 14px;
  /* Rounded corners */
  padding: 16px;
  /* Button padding */
  margin-bottom: 20px;
  cursor: pointer;
  /* Spacing below the button */
}

/* Styles for the "Share" and "Save" buttons container */
.flow-view-footer-share-save-container {
  width: 100%;
  /* Full width */
  display: flex;
  justify-content: space-between;
  /* Space between "Share" and "Save" buttons */
  margin-left: 20px;
  margin-right: 30px;
  margin-bottom: 20px;
  /* Spacing below the buttons container */
}

/* Style for the "Share" button */
.flow-view-footer-share-button {
  flex: 1;

    
      /* Increase border width */
  /* Take half of the available space */
  color: #4c3fdc;
  /* Button background color */
    border: 1px solid #4c3fdc;
      /* Apply an 8px solid black border */
  /* Text color */
  border-radius: 8px;
  /* Rounded corners */
  padding: 16px;
  /* Button padding */
  cursor: pointer;
  
  margin-right: 10px;
  /* Spacing between "Share" and "Save" buttons */
}

/* Style for the "Save" button */
.flow-view-footer-save-button {
  flex: 1;
  /* Take half of the available space */
  
  /* Button background color */
  color: #4c3fdc;

  border: 1px solid #4c3fdc;
    
  /* Text color */
  border-radius: 8px;
  /* Rounded corners */
  padding: 16px;
  /* Button padding */
  margin-left: 10px;
  cursor: pointer;
  /* Spacing between "Share" and "Save" buttons */
}

/* Style for the "View Flows" button */
.flow-view-footer-view-flows-button {
  width: 97%;
  /* Full width */
  background-color: #4c3fdc;
  /* Button background color */
  color: white;
  /* Text color */
  border-radius: 8px;
  /* Rounded corners */
  padding: 16px;
  cursor: pointer;
  /* Button padding */
}

.red-button {
  background-color: #d11a2a;
  /* Button background color */
  color: white;
  /* Text color */
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
  font-family: "Roboto";

  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
  display: flex;
  /* Ensure it's a flex container */
  justify-content: center;
  /* Center content horizontally */
  align-items: center;
  /* Center content vertically, if needed */


  /* Button padding */
}

/* Style for the "View Flows" button */
.blue-button {
  background-color: #4c3fdc;
  /* Button background color */
  color: white;
  /* Text color */
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
  font-family: "Roboto";
  
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
      display: flex;
        /* Ensure it's a flex container */
        justify-content: center;
        /* Center content horizontally */
        align-items: center;
        /* Center content vertically, if needed */
  
  
  /* Button padding */
}


.button-type-1 {
  
  /* Button background color */
  color: #4c3fdc;
  /* Text color */
  /* border-color: #4c3fdc; */
  border-radius: 4px;
  background-color: white;
  
  cursor: pointer;
  
  height: 40px;
  font-size: 14px;
  font-weight: 400;

  /* Button padding */
}

.semi-bold-text-16 {
  margin: 0;
    font-size: 16px;
    font-weight: 600;
}


.playground-flow-interaction-menu {
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}


.playground-step-card {
  display: flex;
  height: 50px;
  
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #4c3fdc;
  /* background-color: #F4F4F4; */

}

.active-step {
  background-color: #FEE7D1;
  border: 1px solid #C76811;
  /* Background color for active step */
  /* color: #fff; */
  /* Text color for active step */
  /* Add any other styles you want for the active step */
}




.step-number {
  border-radius: 4px;
  width: 28px;
  height: 28px;
  /* background-color: #E3E3E3; */
  margin-right: 5px;
  border-color: 1px solid #4c3fdc ;
  background-color: #4c3fdc;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  font-size: 16px;
}

.active-step-number {
  background-color: #C76811;
  /* Background color for active step number */
  color: #fff;
  /* Text color for active step number */
  /* Add any other styles you want for the active step number */
}

.from-title {
  margin-left: 4px;
  align-items: center;
  display: flex;
  font-weight: bold;
  color: #4c3fdc;
  font-size: 16px;
  display: inline-block;
    white-space: nowrap;
    height: 48px;
}

.from-title-active-step {
  margin-left: 4px;
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 16px;
  display: inline-block;
    white-space: nowrap;
    
}



.arrow {
  padding-left: 8px;
  
  padding-right: 8px;
  align-items: center;
  display: flex;
}

.to-title {
  flex: 1;
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 16px;
  color: #4c3fdc;
  display: inline-block;
    white-space: nowrap;
}

.to-title-active-step {
  flex: 1;
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 16px;
  display: inline-block;
    white-space: nowrap;
}

.controls-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-top: 20px; */
  /* margin-left: 20px; */
}

.control-button {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  background-color: #F1F1F1;
  
  display: flex;
  justify-content: center;
  align-items: center;
    border: none;
      
}

.button-image {
  width: 30px;
  height: 30px;
}

.step-text {
  font-size: 16px;
  margin-right: 20px;
}

*:focus {
  outline: none;
    
}

.step-more-container {
  display: flex;
    flex-direction: column;
      margin-left: auto;
      /* top: -2px; */
      /* right: -2px; */
      /* position: relative; */
        /* Right-align the container */
    margin-bottom: 12px;
    /* width: 136px; */
    
    /* align-self: flex-end; */
    /* background-color: #F4F4F4; */
    /* background-color: #4C3FDC; */
    
}


.step-more-container .buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  border-left: 1px solid #C8C8C8;
    /* Left border */
    border-right: 1px solid #C8C8C8;
    /* Right border */
    border-bottom: 1px solid #C8C8C8;
    /* Bottom border */
    border-top: none;
      border-radius:  0 0 4px 4px;;
  
}

.step-more-container .buttons button {
  background: none;
  border: none;
  margin: 0 5px;
  font-size: 14px;
  color: black;
  /* color: #4C3FDC; */
  /* color: #F4F4F4; */
  cursor: pointer;
  display: flex;
  
  
}

.spacer {
  flex: 1;
  /* Takes up remaining space */
}

.step-more-actions {
  /* Add common styles for 'step-more-actions' class here */
  /* For example: */
  color: #4c3fdc;
  /* width: 190px; */
    justify-content: flex-end;

    display: flex;
      /* align-items: center; */
      /* justify-content: space-between; */
      width: 100%;
      /* padding: 4px 8px; */
      height: 100%;
     
      
      
      /* Align items to the right side */
  
  /* Add other common styles */
}

.container {
  top: 0px;
  display: flex;
  align-items: center;
    /* height: 20px; */
        justify-content: center;
          /* Center the content horizontally */
}

.vertical-line {
  /* border-left: 1px solid blue; */
  height: 1px;
    background-color: #4c3fdc;
      width: 100%;
        /* Stretch the line to cover the entire width */
        box-sizing: border-box;
        /* Include border in width calculation */
}


.content {
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  width: 100%;
  /* padding: 4px 8px; */
    height: 100%;
      /* Set the height to 100% to fill the container vertically */
}

.add-step {
  display: flex;
  align-items: center;
}

.add-icon {
  font-size: 24px;
  margin-right: 5px;
}

.options-icon {
  font-size: 24px;
}

.normal-font {
  font-weight: 300;
  /* Adjust the font-weight to make the icon slimmer */
}

.text-view-left-aligned {
background: #fff;
height: 120px;
padding: 12px 16px;
box-sizing: border-box;
font-style: normal;
font-weight: 400;
align-items: center;
color: #211a1e;
border: none;
width: 100%;
font-size: 16px;
line-height: 160%;
resize: none;
border: 1px solid #c4c4c4;
column-count: initial !important;
  writing-mode: horizontal-tb !important;
}

/* TextField Container */
.textField-container {
  width: 300px;
  /* Set the desired width for the TextField */
}

/* TextField Input */
.text-field-input {
  text-align: left;
  /* Align text to the left */
  font-size: 16px;
  /* Adjust font size as needed */
}

/* Additional Styles for Focus */
.text-field-input:focus {
  /* Add any additional focus styles here, if needed */
}

/* Label Styles */
.text-field-label {
  /* Add label styles here, if needed */
}

/* Placeholder Styles */
.text-field-placeholder {
  /* Add placeholder styles here, if needed */
}


.key-container.container {
  padding-top: 25px;
  display: flex;
  height: 30px;
  flex-direction: column;
  align-items: center;
}

.key-line.line {
  flex: 1;
  /* Allow the line to take up available space */
  border-top: 1px solid #ccc;
  width: 100%;
  /* Gray line from left to right */
}

.key-button-container.button-container {
  display: flex;
  justify-content: center;
  top: -16px;
  position: relative;
  /* Center the button horizontally */
}

.key-button.button {
  color: #4C3FDC;
  /* Button background color */
  background-color: white;
  
  /* Button padding */
  border-radius: 4px;
  
  /* Rounded corners */
  cursor: pointer;
  border: 1px solid #4C3FDC;
  display: "flex";
  align-items: "center";
  justify-content: "center";
  height: 32px;
  width: 64px;
  
}

.user-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 20px;
}

.user-info {
  flex: 1;
}

.access-dropdown {
  flex: 1;
  text-align: center;
}

.delete-button {
  flex: 1;
  text-align: right;
}

.image-upload-popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  /* Ensure the popup appears above other elements */
}


.google-btn {
  display: inline-flex;
  align-items: center;
  width: 60%;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #4c3fdc;
  cursor: pointer;
  padding: 8px 16px;
  transition: background-color 0.3s ease;
  font-family: Arial, sans-serif;
  color: #757575;
  outline: none;
}

/* .google-btn:hover {
  background-color: #f5f5f5;
} */

.google-icon {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path fill="#4285F4" d="M14.23 9.49c0-.45-.04-.89-.12-1.32H9v2.51h3.28a3.14 3.14 0 0 1-1.36 2.06v1.71h2.19c1.28-1.18 2.02-2.93 2.02-5.96z"/><path fill="#34A853" d="M9 15.1c1.82 0 3.34-.6 4.45-1.64l-2.19-1.71c-.61.41-1.4.65-2.26.65-1.73 0-3.19-1.17-3.71-2.75H3.55v1.72A8.99 8.99 0 0 0 9 15.1z"/><path fill="#FBBC05" d="M5.29 10.66a5.36 5.36 0 0 0-.29-1.66v-1.7H3.55a8.996 8.996 0 0 0 0 5.37l1.74-1.36z"/><path fill="#EA4335" d="M9 3.4c1.23 0 2.34.42 3.21 1.25l2.41-2.41C12.32 1.21 10.8.6 9 .6A8.996 8.996 0 0 0 3.55 5.05l1.74 1.36c.52-1.59 1.98-2.8 3.71-2.8z"/></svg>') no-repeat center;
  background-size: contain;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.google-text {
  font-size: 18px;
  color: white;
}

.choose-icon-title {
  padding-top: 10px;
  font-size: 20px;
  font-weight: 600;
  
}



/* Basic CSS for the Footer component */

/* Footer container */
footer {
  
  /* Adjust background color */
  
  /* Text color */
  padding: 20px 0;
  /* Adjust padding as needed */
  text-align: center;
}

/* Footer links section */
.footer-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
}

/* Social media links */
.social-media a {
  text-decoration: none;
  
  /* Social media link color */
  margin-right: 10px;
  /* Adjust spacing between social media links */
}

/* Legal links */
.legal-links a {
  text-decoration: none;
  
  /* Legal link color */
  margin-right: 10px;
  /* Adjust spacing between legal links */
}

/* Contact information */
.contact-info span {
  display: block;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  /* Adjust spacing between contact info items */
}

/* Copyright text */
.copyright {
  font-size: 12px;
  /* Adjust font size for copyright text */
}