.accordion {
    position: relative;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  .accordion__button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    background-color: #f2f2f2;
  }
  
  .accordion__button:hover {
    background-color: #ddd;
  }
  
  .accordion__button-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    /* background-image: url('left-arrow.svg'); */
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .accordion__button[aria-expanded="true"] .accordion__button-icon {
    transform: translateY(-50%) rotate(-90deg);
  }
  
  .accordion__content {
    display: none;
    padding: 10px;
  }
  
  .accordion__content[aria-expanded="true"] {
    display: block;
  }
  