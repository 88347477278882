.my-navbar-nav {
    padding-left: 100px;
  }
  
  .logo-div {
    /* width: 80px;
    height: 80px; */
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    
  }

        .logo-div img {
          max-width: 100%;
          max-height: 100%;
        }
        
    .logo-div-view {
      width: 80px;
      height: 80px;
    }
  

    .logo-div-view img {
      max-width: 100%;
      max-height: 100%;
    }
  

  .top-menu-container {
      display: flex;
      flex: 3;
      height: 60px;
      justify-content: space-between; /* Place left and right views at the edges */
      align-items: center;/* Center vertically */
  }

    
  
  .top-menu-diagram-title-text {
    flex: 1;

        display: flex;
          /* Ensure it's a flex container */
          justify-content: center;
          /* Center content horizontally */
          align-items: center;
          /* Center content vertically, if needed */
      /* Place left and right views at the edges */
    font-weight: bold;
    /* padding-left:20px; */
    
    font-size: 20px;
    cursor: pointer;
    
  }

    .top-menu-left-view {
        flex: 1;
          text-align: left;
          font-size: 14px;
          cursor: pointer;
    }

     


                .top-menu-right-view {
                  flex: 1;
                  margin-right: 20px;
                  justify-content: flex-end;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                }
        
                /* Circular Icon styles */
                .top-right-circular-icon {
                  width: 40px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  background-color: lightgray;
                  border-radius: 50%;
                  font-weight: bold;
                  font-size: 20px;
                  
                  /* Make it circular */
                }
        
                /* Content styles */
                .top-right-content {
                  display: flex;
                  flex-direction: column;
                  margin-left: 10px;
                  /* Add spacing between icon and content */
                }
        
                /* Top Title Button styles */
                .top-right-title-button {
                  background-color: transparent;
                  border: none;
                  text-align: left;
                  cursor: pointer;
                }
        
                /* Link Button with Blue Color styles */
                .top-right-link-button {
                  color: blue;
                  background-color: transparent;
                  border: none;
                  cursor: pointer;
                  padding-left: 0;
                  font-weight: bold;
                    text-align: left;
                      /* Ensure button title is left-aligned */
                  /* Add spacing between buttons */
                }


  .nav-right-buttons-div {
    padding-top: 50px;
    display: flex; /* set display property to flex */
    justify-content: space-between; /* evenly distribute items along the main axis */
    align-items: center; /* center items along the cross axis */
    width: 85%; /* make container take up full width */
  }

  
  
  .nav-left-button, .nav-right-button {
    margin: 0 10px; /* add some margin to buttons for spacing */
  }
  
  .nav-left-button {
    order: 1; /* set order of left button */
  }
  
  .nav-right-button {
    order: 4; /* set order of right button */
  }
  
  .nav-content {
    flex: 1; /* set content to take up remaining space */
  }